import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faBars,
  faBell,
  faBooks,
  faExternalLink,
  faHeart,
  faHouse,
  faMagnifyingGlass,
  faPlus,
  faArrowRightFromBracket,
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faArrowRightFromBracket,
  faBars,
  faBell,
  faBooks,
  faExternalLink,
  faHeart,
  faHouse,
  faMagnifyingGlass,
  faPlus,
)
