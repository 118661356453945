import { register, InjectCSS, ReplaceElements, MutationObserver } from '@fortawesome/fontawesome-svg-core/plugins'
import $ from 'jquery'

import '../lib/fontawesome/light'
import '../lib/fontawesome/regular'
import '../lib/fontawesome/solid'

const api = register([InjectCSS, ReplaceElements, MutationObserver])

$(() => {
  api.dom.i2svg()
  api.dom.watch()
})
