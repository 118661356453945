import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faArrowRotateLeft,
  faArrowUp,
  faBars,
  faBook,
  faCartShopping,
  faCircle,
  faCircleInfo,
  faClock,
  faFileCsv,
  faFileLines,
  faFlagPennant,
  faHeadphones,
  faHeart,
  faHouse,
  faLock,
  faLockKeyhole,
  faMagnifyingGlass,
  faPlay,
  faPrint,
  faRightToBracket,
  faSquare,
  faTimes,
  faTriangleExclamation,
  faUpRightFromSquare,
  faXmark,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faArrowRotateLeft,
  faArrowUp,
  faBars,
  faBook,
  faCartShopping,
  faCircle,
  faCircleInfo,
  faClock,
  faFileCsv,
  faFileLines,
  faFlagPennant,
  faHeadphones,
  faHeart,
  faHouse,
  faLock,
  faLockKeyhole,
  faMagnifyingGlass,
  faPlay,
  faPrint,
  faRightToBracket,
  faSquare,
  faTimes,
  faTriangleExclamation,
  faUpRightFromSquare,
  faXmark,
  faAngleLeft,
  faSpinner,
)
