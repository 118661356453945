import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faBookOpen,
  faCheck,
  faCircle,
  faCirclePlay,
  faExchangeAlt,
  faFaceFrown,
  faFaceGrinHearts,
  faFaceLaugh,
  faFaceMeh,
  faFrown,
  faGrinHearts,
  faLaugh,
  faMeh,
  faShareAlt,
  faTimesCircle,
  faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faBookOpen,
  faCheck,
  faCircle,
  faCirclePlay,
  faExchangeAlt,
  faFaceFrown,
  faFaceGrinHearts,
  faFaceLaugh,
  faFaceMeh,
  faFrown,
  faGrinHearts,
  faLaugh,
  faMeh,
  faShareAlt,
  faTimesCircle,
  faQuestionCircle,
)
